import Image from "next/image";

export const Logo = () => (
  <Image
    src="https://scalis-assets.s3.us-east-2.amazonaws.com/public/scalis_brand.svg"
    alt="Scalis Logo"
    width={118}
    height={24}
    priority
  />
);
